import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Loginsuccess from '../views/Loginsuccess.vue'
import searchDetail from '../views/searchDetail.vue'
import myDevice from '../views/myDevice.vue'
import Gettitle from '../utils/pagetitle.js'
import userCenter from '../views/userCenter.vue'
import changeTel from '../views/changeTel.vue'
import Logoutsuccess from '../views/Logoutsuccess.vue'
import ResetPwd from '../views/resetPwd.vue'
import EditUserBaseInfo from '../views/editUserBaseInfo.vue'
import ApplyWorkOrder from '../views/applyWorkOrder.vue'
import EditInfo from '../views/editInfo.vue'
import SuccessPage from '../views/editUserInfoResultPage/successPage.vue'
import FailPage from '../views/editUserInfoResultPage/failPage.vue'
import Shuoming from '../views/editUserInfoResultPage/shuoming.vue'
import changeTelNew from '../views/changeTelNew.vue'
import MyOperationList from '@/views/editUserInfoResultPage/MyOperationList.vue'
import MyOperationDetail from '@/views/editUserInfoResultPage/MyOperationDetail.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/login'
  },
  {
    path: '/login',
    name: 'login',
    meta:{
      title: '用户登录'
    },
    component: Login
  },
  {
    path: '/loginsuccess',
    name: 'loginsuccess',
    meta:{
      title: '登录成功'
    },
    component: Loginsuccess
  },
  {
    path: '/logoutsuccess',
    name: 'logoutsuccess',
    meta:{
      title: '登出成功'
    },
    component: Logoutsuccess
  },
  {
    path: '/searchDetail',
    name: 'searchDetail',
    meta:{
      title: '我的补贴'
    },
    component: searchDetail
  },
  {
    path: '/myDevice',
    name: 'myDevice',
    meta:{
      title: '我的设备'
    },
    component: myDevice
  },
  {
    path: '/userCenter',
    name: 'userCenter',
    meta:{
      title: '用户中心'
    },
    component: userCenter
  },
  {
    path: '/changeTel',
    name: 'changeTel',
    meta:{
      title: '修改手机号'
    },
    component: changeTel
  },
  {
    path: '/changeTelNew',
    name: 'changeTelNew',
    meta:{
      title: '修改手机号'
    },
    component: changeTelNew
  },
  {
    path: '/resetPwd',
    name: 'resetPwd',
    meta:{
      title: '重置密码'
    },
    component: ResetPwd
  },
  {
    path: '/editUserBaseInfo',
    name: 'editUserBaseInfo',
    meta:{
      title: '修改用户基本信息'
    },
    component: EditUserBaseInfo
  },
  {
    path: '/applyWorkOrder',
    name: 'applyWorkOrder',
    meta:{
      title: '申请工单服务'
    },
    component: ApplyWorkOrder
  },
  {
    path: '/editInfo',
    name: 'editInfo',
    meta:{
      title: '申请服务'
    },
    component: EditInfo
  },
  {
    path: '/successPage',
    name: 'successPage',
    meta:{
      title: '修改成功'
    },
    component: SuccessPage
  },
  {
    path: '/failPage',
    name: 'failPage',
    meta:{
      title: '修改失败'
    },
    component: FailPage
  },
  {
    path: '/shuoming',
    name: 'shuoming',
    meta:{
      title: '解决方案'
    },
    component: Shuoming
  },
  {
    path: '/myOperationList',
    name: 'myOperationList',
    meta:{
      title: '我的申请记录',
      keepAlive:true
    },
    component: MyOperationList
  },
  {
    path: '/myOperationDetail',
    name: 'myOperationDetail',
    meta:{
      title: '查看详情'
    },
    component: MyOperationDetail
  }
  


]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  document.title = Gettitle(to.meta.title);
  next();
});


export default router
