<template>
    <div style="background-color: #f3f4f8;height: 100%;padding: 15px;box-sizing: border-box;">
        
        <div v-for="item in operationList" :key="item.id" @click="operationDetail(item)" style="display: flex;justify-content: space-between;align-items: center;background-color: white;padding: 20px;margin-top: 10px;" >
            <div >
                <div style="text-align: start;font-weight: bold;font-size: 4vw;margin-bottom: 1.5vw;">{{item.category}}</div>
                <div style="color: #a6a6a6;font-size: 3.5vw;text-align: start">工单状态：{{item.operationStatus == 0 ? '待审核' : item.operationStatus == 1 ? '审核通过' : '审核未通过'}}</div>
                <div style="text-align: start;color: #a6a6a6;font-size: 3.5vw;">创建时间：{{item.createTime}}</div>
                <!-- <div v-if="item.operationStatus != 0" style="text-align: start;color: #a6a6a6;font-size: 3.5vw;">审核时间：{{item.operationTime}}</div> -->
            </div>
            <div style="font-weight: bold;font-size: 5vw;"><van-icon name="arrow" /></div>
        </div>


  </div>
</template>

<script>


import {selectWorkOrder} from '@/api/wushui/wushui'
export default {
    data() {
        return {
            query:{
                wxcode:'',
            },
            operationList:[]
        };
    },

    created(){
      const searchParams = new URLSearchParams(window.location.search);
      this.wxcode = searchParams.get('code');
      console.log('code',this.wxcode);
      this.query.wxcode = this.wxcode;
      this.initPage();
       
    },

    methods: {
        initPage(){
            
            selectWorkOrder(this.query).then(res=>{
                console.log(res);
                this.operationList = res.rows

            })
        },
        
        operationDetail(operation){
            this.$router.push({path:'/myOperationDetail',query:operation});
        }
    
    },
}
</script>

<style>

</style>