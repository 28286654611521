import request from '@/utils/request'


// 获取设备信息
export function getDeviceList() {
   return request({
    url:'/wushui/getDeviceList',
    method: 'get',
  })
}


export function getDataAndMonth(deviceId,selectCount) {

  const url = '/wushui/getDataAndMonth?deviceId='+deviceId+'&selectCount='+selectCount;
  return request({
    url: url,
    method: 'get'
  })
}

export function selectUserSubsidyList(param) {

  const url = '/wushui/selectUserSubsidyList';
  return request({
    url: url,
    method: 'get',
    params:param
  })
}

//查询已申请补贴
export function querySubsidyByDeviceId(param) {
  return request({
   url:'/wushui/querySubsidyByDeviceId',
   method: 'get',
   params:param
 })
}

//查询已成功领取的补贴
export function querySuccessMoney(deviceId) {
  return request({
    url:'/wushui/querySuccessMoney?deviceId='+deviceId,
    method: 'get'
  })
}

//领取补贴
export function getSubsidy(subsidyList) {
  return request({
    url:'/wushui/getSubsidy',
    method: 'post',
    data: subsidyList
  })
}


//
export function selecPendingTable() {
  return request({
    url:'/wushui/selecPendingTable',
    method: 'get'
  })
}



//更名
export function applyWorkOrder(format) {
  console.log(format)

  return request({
    url:'/wushui/applyWorkOrder',
    method: 'post',
    data: format,
    headers:{'Content-Type': 'multipart/form-data'},
    // transformRequest: [function(data, headers) {
    //   // 去除post请求默认的Content-Type
    //   delete headers.post['Content-Type']
    //   return data
    // }]

  })
}

//更名
export function changeTelOrder(format) {
  console.log(format)

  return request({
    url:'/wushui/changeTelOrder',
    method: 'post',
    data: format,
    headers:{'Content-Type': 'multipart/form-data'},
    // transformRequest: [function(data, headers) {
    //   // 去除post请求默认的Content-Type
    //   delete headers.post['Content-Type']
    //   return data
    // }]

  })
}


export function changeFamerUserTel(query) {

  return request({
    url:'/wushui/changeFamerUserTel',
    method: 'PUT',
    data: query
  })
}


export function getEditChangeTelPage() {
  return request({
    url:'/wushui/getEditChangeTelPage',
    method: 'get'
  })
}



export function selectAddrByParent(pid) {
  return request({
    url:'/wushui/selectAddrByParent?parentAddr='+pid,
    method: 'get'
  })
}


export function selectWorkOrder(param) {

  const url = '/wushui/selectWorkOrder';
  return request({
    url: url,
    method: 'get',
    params:param
  })
}