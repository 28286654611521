<template>
   <div style="background-color: #f3f4f8;height: 100%;padding: 20vw 12vw;box-sizing: border-box;">
    <div v-if="operation.operationStatus == 0" style="color: #0079fe;">
            <van-icon name="clock-o" style="font-weight: bolder;font-size: 25vw;"/>
            <div style="margin: 5vw;color:#666666;font-size: 6vw;font-weight: bolder;">待审核</div>
            <div style="margin: 2vw;color: #666666;">申请时间：{{operation.createTime}}</div>
            <div>
                您的服务申请已提交至后台管理系统，
                为了您的信息安全，管理员将通过人工审核、
                电话回访等方式进行验证，请您理解。
            </div>
            <el-button type="primary" style="position:absolute;bottom: 10vw;left: 10%;width: 80%;" @click="back">返回</el-button>
        </div>
        <div v-if="operation.operationStatus == 1" style="color: #95f204;">
            <van-icon name="passed" style="font-weight: bolder;font-size: 25vw;"/>
            <div style="margin-top: 8vw;color:#666666;font-size: 6vw;font-weight: bolder;">审核通过</div>
            <div style="color: #666666;margin: 5vw;">您申请的工单问题已解决，请返回登录领取补贴</div>
            <div style="margin: 2vw;color: #666666;">申请时间：{{operation.createTime}}</div>
            <div v-if="operation.operationTime !=null" style="margin: 2vw;color: #666666;">审核时间：{{operation.operationTime}}</div>
            <el-button type="primary" style="position:absolute;bottom: 10vw;left: 10%;width: 80%;" @click="back">返回</el-button>
        </div>

        <div v-if="operation.operationStatus == 2" style="color: #e10000;">
            <van-icon name="close" style="font-weight: bolder;font-size: 25vw;"/>
            <div style="margin: 5vw;color:#666666;font-size: 6vw;font-weight: bolder;">审核未通过</div>
            <div style="margin: 2vw;">拒绝原因：{{operation.reason}}</div>
            <div style="margin: 2vw;color: #666666;">申请时间：{{operation.createTime}}</div>
            <div v-if="operation.operationTime !=null" style="margin: 2vw;color: #666666;">审核时间：{{operation.operationTime}}</div>
            <el-button type="primary" style="position:absolute;bottom: 10vw;left: 10%;width: 80%;" @click="toEdit">返回修改</el-button>
        </div>
        
        

  </div>
</template>

<script>


import {selectWorkOrder} from '@/api/wushui/wushui'
export default {
    data() {
        return {
            query:{
                wxcode:'',
            },
            operationList:[],
            operation:{}
        };
    },

    created(){
        
        const searchParams = new URLSearchParams(window.location.search);
        this.wxcode = searchParams.get('code');
        console.log('code',this.wxcode);
        this.query.wxcode = this.wxcode;
        this.initPage();
        
    },
    mounted() {
        // 添加 popstate 事件监听
        window.history.pushState(null, null, window.location.href);
        window.addEventListener('popstate', this.onPopState);
    },
    beforeDestroy() {
        // 在组件销毁前，移除 popstate 事件监听
        window.removeEventListener('popstate', this.onPopState)
    },

    methods: {
        initPage(){
            
            this.operation = this.$route.query;
            console.log(this.operation );
        },
        back(){
            // this.$router.push('/myOperationList');
            location.href='https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7ebdabb02e6faa33&redirect_uri=http://www.wushuizhili.cn/%23%2FmyOperationList&response_type=code&scope=snsapi_base&state=loginsuccess#wechat_redirect'   

        },
        onPopState(e) {
            location.href='https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7ebdabb02e6faa33&redirect_uri=http://www.wushuizhili.cn/%23%2FmyOperationList&response_type=code&scope=snsapi_base&state=loginsuccess#wechat_redirect'   

        },
        toEdit(){
            location.href='https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7ebdabb02e6faa33&redirect_uri=http://www.wushuizhili.cn/%23%2FchangeTelNew&response_type=code&scope=snsapi_base&state=loginsuccess#wechat_redirect'
        }
    
    },
}
</script>

<style>

</style>